<template>
  <div class="form-elements">
    <vuestic-widget >
      <div class="row">
        <div class="col-md-3">
          <button
            type="button"
            v-on:click="back"
            class="btn btn-primary btn-sm"
          >{{'view.typecredit.back' | translate}}</button>
        </div>
        <div class="col-md-3 offset-md-6">
          <button
            type="button"
            v-on:click="trashMembership"
            class="btn btn-warning btn-sm"
          >Delete Element</button>
        </div>
      </div>
    </vuestic-widget>
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="'Actualizar Membresia'">
          <form @submit.prevent="editZoneAccess">
            <div class="row">
              <div class="col-md-12">
                <fieldset>
                  <div class="row">
                     <div class="col-md-6">
                        <label>Membresia</label>
                      <multiselect
                        v-model="membership"
                        label="name"
                        placeholder="Selecciona un elemento"
                        :options="memberships"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          Membresia:
                          <label style="font-weight:600">{{ option.name }}</label>
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-md-6 with-icon-right">
                        <label>Zona de Evento</label>
                      <multiselect
                        v-model="zoneevent"
                        label="name"
                        placeholder="Selecciona un elemento"
                        :options="zoneevents"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          Zona de Evento:
                          <label style="font-weight:600">{{ option.name }}</label>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </fieldset>
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <label
                      class="control-label"
                      for="location"
                    >{{'view.typecredit.fields.status' | translate}}</label>

                    <div
                      class="form-group with-icon-right"
                      :class="{'has-error': errors.has('active')}"
                    >
                      <div class="input-group">
                        <vuestic-switch v-model="active">
                          <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                          <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                        </vuestic-switch>
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                        
                        <small
                          v-show="errors.has('active')"
                          class="help text-danger"
                        >{{ errors.first('active') }}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 offset-md-12">
                  <button class="btn btn-primary btn-sm" type="submit">{{$t('view.team.update')}}</button>
                </div>
              </div>
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";

import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
export default {
  name: "update-zone-access",
  components: {
    SpringSpinner,
    Multiselect
  },
  data() {
    return {
      membership: "",
      memberships: [],
      zoneevent: "",
      zoneevents: [],
      active: 1
    };
  },
  mounted() {
    var id = this.$route.params.id;
    if (id !== null) {
          this.loadMembershipsByEvent(this.idevent)
      .then(data => {
        this.memberships = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
    this.loadZoneeventByEvent(this.idevent)
      .then(data => {
        this.zoneevents = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
      this.loadZoneaccess(id)
        .then(data => {
          this.active = data.active;
          this.zoneevent = data.zone_event;
          this.membership = data.membership;
        })
        .catch(data => {
          this.addToastMessage({
            text: "Ocurrio un error",
            type: "success"
          });
        });
    } else {
      this.addToastMessage({
        text: "Ocurrio un error",
        type: "success"
      });
    }
  },
   computed: {
    ...mapState({
      //products: state => state.product.products,
      idevent: state => state.event.event.id,
      idcompany: state => state.auth.company.id
    })
  },
  methods: {
    ...mapActions([
      "loadZoneaccess",
      "updateZoneaccess",
      "deleteZoneaccess",
      "loadZoneeventByEvent",
      "loadMembershipsByEvent",
      "addToastMessage"
    ]),
    back() {
      this.$router.go(-1);
    },
    editZoneAccess() {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id;
          this.updateZoneaccess({
            id_zone_event: this.zoneevent.id,
            id_membership: this.membership.id,
            active: this.active,
            id: id
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              setTimeout(() => this.$router.go(-1), 2000);
            })
            .catch(data => {
              this.error = data.message;
              this.errors = data.errors || {};
            });
          //this.$refs.vuetable.refresh();
        }
      });
    },
    trashMembership() {
      var id = this.$route.params.id;
     // console.log(id);
      this.deleteZoneaccess({
       
        id: id
      })
        .then(data => {
          this.addToastMessage({
            text: data.message,
            type: "warning"
          });
          setTimeout(() => this.$router.go(-1), 100);
        })
        .catch(data => {
          this.error = data.message;
          this.errors = data.errors || {};
        });
      //this.$refs.vuetable.refresh();
    },
    cleanform() {
      this.name = null;
      this.description = null;
    }
  },
  
};
</script>
